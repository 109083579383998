<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <!-- <a-table-column key="code" title="编号" align="center" data-index="code" width="80px" /> -->
    <a-table-column key="username" title="员工" align="center" data-index="username" width="80px" />
    <a-table-column key="cellphone" title="手机号" align="center" data-index="cellphone" width="100px" />
    <a-table-column key="email" title="邮箱" align="center" data-index="email" width="100px" />
    <a-table-column key="mechanismName" title="所属机构" align="center" data-index="mechanismName" width="100px" />
    <a-table-column key="postName" title="职务" align="center" data-index="postName" width="80px" />
    <a-table-column key="roleNames" title="角色名称" align="center" data-index="roleNames" width="80px" />
    <a-table-column key="createTimestamp" title="添加时间" align="center" data-index="createTimestamp" width="80px" />
    <!-- <a-table-column key="status" title="启用" align="center" data-index="status" width="80px">
      <template slot-scope="status">
        <a-badge
          :status="{ AVAILABLE: 'success', UNAVAILABLE: 'error' }[status]"
          :text="status | StatusText()"
        ></a-badge>
      </template>
    </a-table-column> -->
    <a-table-column key="actions" title="操作" align="center" width="140px">
      <template slot-scope="record">
        <a-space>
          <a-button v-if="$auth('user_update')" type="link" size="small" @click="handleEdit(record)"
            ><a-icon type="edit" />编辑</a-button
          >
          <a-button
            v-if="$auth('user_delete')"
            type="link"
            size="small"
            @click="handleRemove(record)"
            class="error-color"
            ><a-icon type="delete" />删除</a-button
          >
          <!-- <a-button v-if="$auth('user_update')" type="link" size="small" @click="handleStatus(record)"
            ><a-icon type="edit" />{{ record.status | StatusTextReverse() }}</a-button
          > -->
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as userApi from '@/api/user'
import { COMMON_STATUS_REVERSE } from '@/store/dictionary'
import store from '@/store'

export default {
  data() {
    return {
      listLoading: false,
      list: [],
      query: {},
      pagination: {}
    }
  },
  mounted() {},
  methods: {
    initData(query = {}) {
      this.query = query
      this.pagination = this.initPagination()
      return this.getData()
    },
    getData() {
      this.listLoading = true

      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      let Pagination = { pageIndex, pageSize }

      const tempPagination = JSON.parse(window.sessionStorage.getItem('permissionUserPagination'))
      console.log(tempPagination)
      if (tempPagination) {
        Pagination = { ...Pagination, ...{ tempPagination } }
        const { pageIndex: current, pageSize } = tempPagination
        this.pagination = { ...this.pagination, ...{ current, pageSize } }
        window.sessionStorage.removeItem('permissionUserPagination')
      }
      return userApi
        .list({ ...query, ...Pagination })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
          store.dispatch('getInfo')
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return userApi
            .remove(record.id)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    },
    //  启用禁用
    handleStatus(record) {
      const label = COMMON_STATUS_REVERSE.filter(v => v.value === record.status)[0].label
      const params = { userId: record.id, isEnableUser: record.status !== 'AVAILABLE' }
      const confirm = this.$confirm({
        title: '提示',
        // eslint-disable-next-line no-undef
        content: `您确定要【${label}】${record.username}${record.cellphone}吗？`,
        onOk: () => {
          return userApi
            .enable(params)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              console.log(err)
              confirm.destroy()
            })
        }
      })
    },
    //  编辑
    handleEdit({ id }) {
      const {
        pagination: { current: pageIndex, pageSize }
      } = this
      const query = { pageIndex, pageSize }
      window.sessionStorage.setItem('permissionUserPagination', JSON.stringify(query))
      this.$router.push({
        name: 'PermissionUserEdit',
        params: { id }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-badge-status-text,
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
