<template>
  <div>
    <a-card :bordered="false" style="margin-bottom:24px">
      <a-form-model class="ant-advanced-search-form">
        <a-row :gutter="[24, 0]">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="用户姓名：">
              <a-input-search v-model="query.userName" placeholder="请输入" style="width: 100%" @search="initData" />
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="手机号：">
              <a-input-search v-model="query.cellphone" placeholder="请输入" style="width: 100%" @search="initData" />
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="编号：">
              <a-input-search v-model="query.code" placeholder="请输入" style="width: 100%" @search="initData" />
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="状态：">
              <h-select v-model="query.status" allowClear placeholder="请选择" @change="() => initData()">
                <a-select-option
                  v-for="statusInfo in statusOptions"
                  :key="statusInfo.value"
                  :value="statusInfo.value"
                  >{{ statusInfo.label }}</a-select-option
                >
              </h-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="所属机构：">
              <a-input-search
                v-model="query.mechanismName"
                allowClear
                placeholder="请输入机构名称"
                style="flex:1"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title" style="display: flex;justify-content: space-between">
        <span>员工管理</span>
        <div class="statistics">
          <span
            >员工创建上限：{{
              maximumNumber.userRegistrationLimit === unlimitedNumber ? '无限制' : maximumNumber.userRegistrationLimit
            }}</span
          >
          <span>已有员工数量：{{ maximumNumber.userRegistrationCount }}</span>
        </div>
      </div>
      <div slot="extra">
        <a-button
          v-if="$auth('user_add')"
          type="primary"
          shape="round"
          @click="$router.push('/permission/user/create')"
          :disabled="maximumNumber.userRegistrationCount >= maximumNumber.userRegistrationLimit"
          ><a-icon type="plus" />添加员工</a-button
        >
      </div>
      <main-table ref="table"></main-table>
    </a-card>
  </div>
</template>

<script>
import MainTable from './components/Table'
import { COMMON_STATUS } from '@/store/dictionary'
import * as commonApi from '@/api/common'
import { mapState } from 'vuex'
export default {
  name: 'PermissionUserList',
  components: {
    MainTable
  },
  data() {
    return {
      query: { userName: '', cellphone: '', code: '', mechanismId: '', status: undefined, mechanismName: undefined },
      statusOptions: COMMON_STATUS,
      // 学员管理、机构管理、员工管理上限
      maximumNumber: {}
    }
  },
  computed: {
    ...mapState({
      // 无限制的数量
      unlimitedNumber: state => state.user.unlimitedNumber
    })
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)

      // 学员管理、机构管理、员工管理上限
      commonApi.commonGetMechanism().then(res => {
        const { code, data } = res
        if (code === 0) {
          this.maximumNumber = data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-advanced-search-form /deep/ .ant-input-group {
  background: #fff;
}

.statistics {
  span {
    margin-right: 24px;
    color: #595959;
    font-size: 14px;
  }
}
</style>
